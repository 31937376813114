<template>
  <div class="h-screen bg-gray-100 flex flex-col justify-center sm:py-12">
    <div class="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
      <h1 class="font-bold text-center text-2xl mb-5" >{{$t("txt-resetMyEmail")}}</h1>  
      <div class="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
        <div class="px-5 py-7">
          <form autocomplete="off"> 
            <label for="email" v-if="!form.token" class="font-semibold text-sm text-gray-600 pb-1 block">E-mail</label>
            <input id="email" v-if="!form.token" autocomplete="email" v-model="form.email" type="email" :class="{'border': !form.id}" class="rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" />
            <small class="font-bold text-red-600" v-if="!form.token">{{$t("txt-whatsEmail")}}</small> 
            
            <label v-if="form.token" for="password" class="font-semibold text-sm text-gray-600 pb-1 block">{{$t("txt-newpass")}}</label>
            <input v-if="form.token" id="password" name="password" autocomplete="new-password" v-model="form.password" type="password" class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" />

            <label v-if="form.token" for="confirm-password" class="font-semibold text-sm text-gray-600 pb-1 block">{{$t("txt-confirm-pass")}}</label>
            <input v-if="form.token" id="confirm-password" name="confirm-password" autocomplete="confirm-password" v-model="confirmPass" type="password" class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" />
            
            <button v-if="!form.token" :disabled="form.email === ''" @click="reset" type="button" class="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">
                <span class="inline-block mr-2">{{$t("txt-sendEmail")}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
            </button>
            <button v-if="form.token" :disabled="form.password === ''" @click="reset" type="button" class="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">
                <span class="inline-block mr-2">{{$t("txt-resetMyEmail")}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
            </button>
          </form>
        </div>
      </div>

      <router-link class="text-center w-full block mt-2 underline" to="/login">{{$t("txt-back")}}</router-link>
    </div>
  
  </div>
</template>

<script>

export default {
  name: 'Reset',
  data: function () {
    return {
      confirmPass : '',
      form: {
        email: '',
        password: '',
        token: false,
      },
    }
  },
  methods: {
    async reset() {
      if (!this.form.token) {
        const resp = await this.$http.post('/email-reset-senha', this.form);
        this.form.email = '';

        if (!resp.data.success) {
          this.$vToastify.error(this.$t(resp.data.err));
        } else {
          this.$vToastify.success(this.$t("feedback-sentReset"));
          this.$router.push({path: '/login'}).catch(()=>{});
        }
      } else {
        if(this.form.password !== this.confirmPass) {
          this.confirmPass = "";
          return this.$vToastify.error(this.$t("feedback-passNotEqualConfirmPass"));
        }
        await this.$http.post(`/reset-senha`, this.form);
        this.$router.push({path: '/login'}).catch(()=>{});
        this.$vToastify.success(this.$t("feedback-passReseted"));
      }
    }
  },
  async beforeMount() {
    if (this.$store.state.isLogged) {
      return this.$router.push({path: '/dashboard'});
    }
    
    if (this.$route.params.token) {
      this.form.token = this.$route.params.token;
    }

    const passField = document.getElementById('password');

    if (passField) {
      passField.onfocus = function() {
        passField.removeAttribute('readonly');
      };
    }
  },
}
</script>
